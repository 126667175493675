"use client"

import { Card } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import { MapPin, Globe, Building2, Copy, CheckCircle2, Shield, Clock, Activity, Lock, Server, ChevronUp, ChevronDown, AlertTriangle, Network } from "lucide-react"
import type { LucideIcon } from 'lucide-react'
import { useState } from "react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { LocationMap } from "@/components/location-map"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { type IPDisplayProps, type ConnectionType } from "@/types/ip"

export function IPDisplay({ ipInfo, isLoading = false }: IPDisplayProps) {
  const [copied, setCopied] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showIPv6, setShowIPv6] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const copyIP = async (ip: string) => {
    try {
      await navigator.clipboard.writeText(ip)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy IP:', err)
    }
  }

  const isIPv6 = (ip: string | undefined): boolean => {
    return Boolean(ip?.includes(':'))
  }

  const formatIPv6 = (ip: string | undefined) => {
    if (!ip) return { firstHalf: '', secondHalf: '' }
    
    // Remove empty groups once
    const normalizedIP = ip.replace(/::/, ':0000:').replace(/\b(?:0+:){2,}/, ':');
    const groups = normalizedIP.split(':');
    
    // Format into two rows of 4 groups each
    const firstHalf = groups.slice(0, 4).join(':') + ':';
    const secondHalf = groups.slice(4).join(':');
    
    return { firstHalf, secondHalf };
  }

  // Add check for both IPv4 and IPv6 availability
  const hasIPv6 = Boolean(ipInfo?.ipv6 && ipInfo?.ipv6Data)
  const hasIPv4 = Boolean(ipInfo?.ipv4 && ipInfo?.ipv4Data)
  const showVersionSwitch = hasIPv4 && hasIPv6

  // Get current IP and data based on version
  const currentIP = showIPv6 ? ipInfo?.ipv6 : ipInfo?.ipv4
  const currentIPData = showIPv6 ? ipInfo?.ipv6Data : ipInfo?.ipv4Data

  if (error) {
    return (
      <Card className="w-full max-w-3xl p-6">
        <div className="text-center text-red-500">
          <AlertTriangle className="mx-auto h-8 w-8 mb-2" />
          <p>Error loading IP information: {error}</p>
        </div>
      </Card>
    )
  }

  if (isLoading) {
    return <IPDisplaySkeleton />
  }

  if (!ipInfo) {
    return <IPDisplaySkeleton />
  }

  // Default data object
  const defaultData = {
    city: 'Unknown',
    region: 'Unknown',
    country: 'Unknown',
    org: 'Unknown',
    is_proxy: false,
    is_tor: false,
    connection_type: 'Unknown' as ConnectionType,
    latitude: 0,
    longitude: 0,
    isBlacklisted: false,
    blacklistCount: 0,
    blacklistDetails: [],
    postal: 'Unknown',
    timezone: 'Unknown',
    loc: '',
    threat_score: 0,
    privacy_score: 0,
    latency: 0,
    hosting_provider: 'Unknown'
  }

  // Merge with root level data from ipInfo
  const mergedData = {
    ...defaultData,
    ...ipInfo,
    ...currentIPData
  }

  const mainInfo = [
    {
      icon: <MapPin className="h-5 w-5" />,
      label: "Location",
      value: currentIPData ? `${currentIPData.city}, ${currentIPData.region}, ${currentIPData.country}` : 'Unknown'
    },
    {
      icon: <Globe className="h-5 w-5" />,
      label: "ISP",
      value: currentIPData?.org || 'Unknown'
    },
    {
      icon: <Shield className="h-5 w-5" />,
      label: "Security Status",
      value: currentIPData?.is_proxy ? "Proxy/VPN Detected" : "Direct Connection"
    },
    {
      icon: <Activity className="h-5 w-4" />,
      label: "Connection",
      value: showIPv6 ? "IPv6 Connection" : "IPv4 Connection"
    }
  ]

  const additionalInfo = [
    {
      icon: <Building2 className="h-5 w-5" />,
      label: "Postal Code",
      value: mergedData.postal
    },
    {
      icon: <Clock className="h-5 w-5" />,
      label: "Timezone",
      value: mergedData.timezone
    },
    {
      icon: <Lock className="h-5 w-5" />,
      label: "Privacy Score",
      value: `${mergedData.privacy_score}/100`
    },
    {
      icon: <Server className="h-5 w-5" />,
      label: "Hosting Provider",
      value: mergedData.hosting_provider
    }
  ]

  return (
    <Card className="relative overflow-hidden border-0 bg-white/20 p-4 sm:p-8 backdrop-blur-xl dark:bg-gray-900/20">
      <div className="absolute inset-0 bg-gradient-to-br from-primary/5 via-transparent to-primary/5" />
      <div className="relative space-y-6 sm:space-y-8">
        <div className="text-center">
          <div className="flex items-center justify-center gap-2">
            <h2 className="text-lg sm:text-xl font-medium text-gray-600 dark:text-gray-300">Your IP Address</h2>
            
            {showVersionSwitch && (
              <div className="flex items-center gap-2 rounded-full bg-gray-100 px-3 py-1 dark:bg-gray-800">
                <Label 
                  htmlFor="ip-version" 
                  className={cn(
                    "text-sm cursor-pointer",
                    !showIPv6 ? "text-primary" : "text-muted-foreground"
                  )}
                >
                  IPv4
                </Label>
                <Switch
                  id="ip-version"
                  checked={showIPv6}
                  onCheckedChange={setShowIPv6}
                />
                <Label 
                  htmlFor="ip-version" 
                  className={cn(
                    "text-sm cursor-pointer",
                    showIPv6 ? "text-primary" : "text-muted-foreground"
                  )}
                >
                  IPv6
                </Label>
              </div>
            )}
          </div>

          <div className="mt-4 flex flex-col items-center gap-4">
            <div className="group relative flex items-center justify-center gap-3">
              {currentIP && isIPv6(currentIP) ? (
                <div className="flex flex-col items-center gap-1 font-mono text-base sm:text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white break-all px-2 sm:px-4">
                  <span className="w-full text-center">{formatIPv6(currentIP).firstHalf}</span>
                  <span className="w-full text-center">{formatIPv6(currentIP).secondHalf}</span>
                </div>
              ) : (
                <p className="font-mono text-2xl sm:text-4xl lg:text-6xl font-bold tracking-tight text-gray-900 dark:text-white break-all px-2 sm:px-4">
                  {currentIP || 'Unknown'}
                </p>
              )}
              
              <button
                onClick={() => currentIP && copyIP(currentIP)}
                className="group rounded-full p-2 transition-all hover:bg-gray-100 dark:hover:bg-gray-800"
                aria-label="Copy IP address"
                disabled={!currentIP}
              >
                {copied ? (
                  <CheckCircle2 className="h-5 w-5 text-green-500" />
                ) : (
                  <Copy className="h-5 w-5 text-gray-400 transition-colors group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-300" />
                )}
              </button>
            </div>

            <div className="grid w-full grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-3">
              <SecurityStatusCard
                title="VPN Status"
                status={mergedData.is_proxy}
                icon={Shield}
                good="Protected by VPN"
                bad="Not Protected"
                details={[
                  mergedData.is_proxy 
                    ? "Your connection is secured through a VPN" 
                    : "Your real IP address is visible to websites",
                  `Provider: ${mergedData.org || 'Unknown'}`
                ]}
              />
              
              <SecurityStatusCard
                title="Blacklist Status"
                status={!mergedData.isBlacklisted}
                icon={AlertTriangle}
                good="Clean IP"
                bad={`Listed (${mergedData.blacklistCount} sources)`}
                details={[
                  mergedData.isBlacklisted 
                    ? `Found in ${mergedData.blacklistCount} blacklists` 
                    : "No blacklist records found",
                  ...(mergedData.blacklistDetails || [])
                ]}
              />

              <SecurityStatusCard
                title="Connection Type"
                status={mergedData.connection_type === 'Residential'}
                icon={Network}
                good="Residential IP"
                bad={mergedData.connection_type || "Datacenter IP"}
                details={[
                  mergedData.connection_type === 'Residential'
                    ? "✓ Residential IP (most trusted by websites)"
                    : "⚠ Datacenter IP (may be restricted by some services)",
                  `Network: ${mergedData.connection_type || 'Unknown'}`,
                  `Provider: ${mergedData.org || 'Unknown'}`,
                  showIPv6 ? "Using IPv6" : "Using IPv4"
                ]}
              />

              <SecurityStatusCard
                title="Tor Status"
                status={!mergedData.is_tor}
                icon={Lock}
                good="No Tor"
                bad="Tor Detected"
                details={[
                  mergedData.is_tor 
                    ? "⚠️ Using Tor network - may be blocked by some services" 
                    : "✓ Standard network connection",
                  "Tor exit nodes are often blocked by:",
                  "• Banking websites",
                  "• Email services",
                  "• Shopping sites",
                  "• Streaming platforms"
                ]}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
          {mainInfo.map((item, index) => (
            <InfoItem
              key={index}
              icon={item.icon}
              label={item.label}
              value={item.value}
            />
          ))}
        </div>

        <LocationMap 
          lat={ipInfo.latitude} 
          lng={ipInfo.longitude} 
          city={ipInfo.city}
        />

        <div className="border-t pt-8">
          <Button
            variant="ghost"
            onClick={() => setShowDetails(!showDetails)}
            className="mx-auto flex items-center gap-2 text-sm font-medium text-muted-foreground hover:text-foreground"
          >
            {showDetails ? "Show Less" : "Show More Details"}
            {showDetails ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </Button>

          <div className={cn(
            "grid grid-cols-1 gap-6 transition-all duration-300 md:grid-cols-2",
            showDetails ? "opacity-100 mt-8" : "hidden opacity-0"
          )}>
            {additionalInfo.map((item, index) => (
              <InfoItem
                key={index}
                icon={item.icon}
                label={item.label}
                value={item.value}
              />
            ))}
          </div>
        </div>
      </div>
    </Card>
  )
}

function InfoItem({ icon, label, value }: { icon: React.ReactNode; label: string; value: string }) {
  const isLongText = value.length > 30

  return (
    <div className="group rounded-2xl border bg-card p-4 transition-all hover:border-primary/50 hover:shadow-lg">
      <div className="flex items-start gap-3">
        <div className="shrink-0 rounded-full bg-primary/10 p-2 text-primary">
          {icon}
        </div>
        <div className="min-w-0 flex-1">
          <p className="text-sm font-medium text-muted-foreground">{label}</p>
          {isLongText ? (
            <TooltipProvider>
              <Tooltip delayDuration={300}>
                <TooltipTrigger asChild>
                  <p className="mt-1 cursor-default truncate font-medium text-foreground">
                    {value || "Unknown"}
                  </p>
                </TooltipTrigger>
                <TooltipContent 
                  side="bottom" 
                  className="max-w-[300px] break-words text-sm"
                >
                  {value}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <p className="mt-1 font-medium text-foreground">
              {value || "Unknown"}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

function IPDisplaySkeleton() {
  return (
    <Card className="w-full max-w-3xl p-6 space-y-6">
      <div className="text-center">
        <Skeleton className="h-8 w-48 mx-auto mb-2" />
        <Skeleton className="h-12 w-64 mx-auto" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Array(4).fill(0).map((_, i) => (
          <div key={i} className="flex items-start space-x-3">
            <Skeleton className="h-5 w-5" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-20" />
              <Skeleton className="h-4 w-32" />
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}

// Update the SecurityStatusCard props interface
interface SecurityStatusCardProps {
  title: string
  status: boolean
  icon: LucideIcon
  good: string
  bad: string
  details?: string[]
}

function SecurityStatusCard({ 
  title, 
  status, 
  icon: Icon, 
  good, 
  bad,
  details
}: SecurityStatusCardProps) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={cn(
            "flex items-center gap-2 rounded-lg border p-2",
            status 
              ? "border-green-500/20 bg-green-500/10 text-green-700 dark:text-green-400" 
              : "border-red-500/20 bg-red-500/10 text-red-700 dark:text-red-400"
          )}>
            <Icon className="h-4 w-4" />
            <div className="min-w-0 flex-1">
              <p className="text-xs font-medium opacity-80">{title}</p>
              <p className="truncate text-sm font-semibold">
                {status ? good : bad}
              </p>
            </div>
          </div>
        </TooltipTrigger>
        {details && details.length > 0 && (
          <TooltipContent>
            <ul className="list-disc pl-4">
              {details.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  )
}
