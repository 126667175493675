"use client"

import { useState } from "react"
import dynamic from "next/dynamic"
import { ChevronDown, ChevronUp, MapPin } from "lucide-react"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"

// Dynamically import the map with SSR disabled
const MapComponent = dynamic(
  () => import("./map"),
  { 
    loading: () => (
      <div className="h-[300px] w-full animate-pulse rounded-xl bg-gray-100 dark:bg-gray-800" />
    ),
    ssr: false // This is crucial for leaflet
  }
)

export function LocationMap({ lat, lng, city }: { lat: number; lng: number; city: string }) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="mt-6 rounded-xl border bg-card">
      <Button
        variant="ghost"
        className="w-full flex items-center justify-between p-4 hover:bg-accent"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-2">
          <MapPin className="h-4 w-4 text-primary" />
          <span className="font-medium">View Location on Map</span>
        </div>
        {isExpanded ? (
          <ChevronUp className="h-4 w-4" />
        ) : (
          <ChevronDown className="h-4 w-4" />
        )}
      </Button>
      
      <div
        className={cn(
          "overflow-hidden transition-all duration-300",
          isExpanded ? "h-[300px]" : "h-0"
        )}
      >
        {isExpanded && (
          <div className="h-full w-full">
            <MapComponent lat={lat} lng={lng} city={city} />
          </div>
        )}
      </div>
    </div>
  )
}